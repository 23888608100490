<template>
    <div class="map-page map-page-list map-page-countries-list" :class="{'map-loading': loading }">
        <div class="map-page-content">
            <div class="map-admin-list">
                <header class="map-admin-list-header">
                    <h1>List of countries</h1>
                    <panel
                        class="map-search-panel"
                        :dark="true"
                        >
                        <base-input
                            :round="true"
                            v-model="search_term"
                            :placeholder="`Search countries...`"
                            @change="filter_countries"
                            >
                        </base-input>
                        <Button class="map-search-button" icon="icon-search" color="primary" @click="filter_countries">
                            Search
                        </Button>
                    </panel>
                </header>
                <div class="map-table map-countries-table" :class="{ 'map-loading': loading }">
                    <header>
                        <div class="map-table-col">#</div>
                        <div class="map-table-col">Name</div>
                        <div class="map-table-col">Visible in Case Study</div>
                        <div class="map-table-col" v-if="logged_user.role === 'super-admin'">Actions</div>
                    </header>
                    <div class="map-table-body">
                        <loader />
                        <p class="map-table-empty" v-if="!has_countries && !loading">There are no items to display.</p>
                        <div class="map-table-row" v-for="(country, key) in filtered_countries" :key="key">
                            <div class="map-table-col">{{ key + 1 }}</div>
                            <div class="map-table-col">{{ country.name }}</div>
                            <div class="map-table-col">
                                <checkbox v-model:checked="country.visible_in_case_study" @change.stop="save(country)"></checkbox>
                            </div>
                            <div class="map-table-col map-no-bg" v-if="logged_user.role === 'super-admin'">
                                <a :href="`/admin/countries/${country.id}/edit`" class="map-button map-color-primary map-button-edit"></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Loader from '@/components/Loader';
    import Checkbox from '@/components/Inputs/Checkbox';
    import Button from '@/components/Buttons/Button';
    import Panel from '@/components/Panels/Panel';
    import BaseInput from '@/components/Inputs/BaseInput';

    import { CountryService } from '@/services';

    export default
    {
        name: 'PostsList',
        components:
        {
            Loader,
            Button,
            Panel,
            BaseInput,
            Checkbox,
        },
        data()
        {
            return {
                loading: false,
                has_countries: false,
                search_term: '',
                countries: [],
                filtered_countries: [],
            };
        },
        computed:
        {
            logged_user()
            {
                return this.$store.getters['Auth/user'];
            },
        },
        async mounted()
        {
            await this.get_countries();
        },
        methods:
        {
            filter_countries()
            {
                this.filtered_countries = this.countries.filter((country) =>
                {
                    return (country.name + ' ' + country.code + ' ' + country.content).toLowerCase().includes(this.search_term.toLowerCase());
                });

                this.has_countries = this.filtered_countries && this.filtered_countries.length;
            },

            async get_countries()
            {
                this.loading = true;

                this.countries = (await CountryService.all({ 'order-by': 'visible_in_case_study,name' })).data;

                this.has_countries = this.countries && this.countries.length;

                this.countries.forEach((country) => country.visible_in_case_study = !!country.visible_in_case_study);

                this.filtered_countries = this.countries;

                this.loading = false;
            },

            async save(item)
            {
                CountryService.update(item.id, { visible_in_case_study: item.visible_in_case_study }).then(() =>
                {
                    this.$toast.success('Country was successfully updated!');
                },
                (error) =>
                {
                    if (error.data.message)
                    {
                        this.$toast.error(error.data.message);
                    }
                    else
                    {
                        this.$toast.error('Something went wrong. Please try again!');
                    }
                });
            },
        },
    }
</script>
